import React from 'react';

const EXPERTISE_DATA = [
  {
    title: 'Game Analysis',
    description: [
      'Deconstruction of games, art style, monetization, etc',
      'Understand audience',
      'Review competitors',
    ],
  },
  {
    title: 'Data Analysis',
    description: [
      'Analyze game KPIs (e.g. retention, playtime, funnels, churn analysis, LTV, ARPU, etc)',
      'Meaningful interpretation of data',
      'Identify improvement areas',
    ],
  },
  {
    title: 'KPI Driven Game Design',
    description: [
      'Set KPI targets',
      'High impact design propositions for targeted KPIs',
      'Focus on improving monetization, retention, level funnels, playtime, etc',
    ],
  },
  {
    title: 'Product Management',
    description: [
      'End-to-end product ownership',
      'Product alignment with KPIs targets',
      'Backlog maintenance',
      'Devise product strategies',
      'Game production',
    ],
  },
  {
    title: 'Game Development',
    description: [
      'Agile sprints',
      'Game design, art & engineering',
      'Software architecture design',
      'QA',
    ],
  },
  {
    title: 'A/B Testing',
    description: [
      'Define A/B test hypotheses',
      'ROI-first approach (low effort, high impact tests first)',
      'Product alignment based on test results',
    ],
  },

  {
    title: 'Data Driven Decisions',
    description: [
      'Analyse KPIs after each test (improvements/degradation)',
      'Leverage past lessons',
      'Trends analysis',
    ],
  },
  {
    title: 'Product Housekeeping',
    description: [
      'Technical debt management',
      '3rd-party SDK integration & updates',
      'Code refactoring',
      'Project optimisation',
      'App store compatibility',
    ],
  },
  {
    title: 'Game LiveOps',
    description: [
      'Player segmentation',
      'Dynamic IAP & RV offers',
      'Live events calendar',
      'In-game messaging',
    ],
  },
];

function ExpertiseSection() {
  return (
    <section className="expertise-section">
      <h1 className="section-heading mb-5">Our Complete LiveOps Expertise</h1>
      <div className="expertise-container">
        {EXPERTISE_DATA.map((expertise, index) => (
          <>
            <div className="expertise-card">
              <h2 className="expertise-card-count m-0">0{index + 1}</h2>

              <h3 className="expertise-heading">{expertise.title}</h3>
              <ul className="expertise-description m-0">
                {expertise.description.map(data => (
                  <li>{data}</li>
                ))}
              </ul>
            </div>
          </>
        ))}
      </div>
    </section>
  );
}

export default ExpertiseSection;
