import React from 'react';
import CaseStudyCarousel from './CaseStudyCarousal/CaseStudyCarousal';

function CaseStudySection() {
  return (
    <section className="case-study-section">
      <h1 className="section-heading case-study-heading mb-5">Case Studies</h1>
      <CaseStudyCarousel />
    </section>
  );
}

export default CaseStudySection;
