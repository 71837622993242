import React from 'react';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../../Shared/Link/Link';
import tintashLogoSrc from '../../../../assets/images/connect/live-ops/tintash-logo-white.svg';
import headingImage from '../../../../assets/images/connect/live-ops/heading-image.svg';

function HeadingSection() {
  return (
    <section className="heading_wrapper">
      <GatsbyLink to="/" className="heading-link">
        <img src={tintashLogoSrc} alt="Tintash Logo" />
      </GatsbyLink>
      <div className="heading-container">
        <div className="heading-subsection">
          <div className="heading-title">
            We <span className="text__yellow  ">Boost LTVs</span> Of Your Top Mobile Games, While
            You Focus On Your Next Big Hit!
          </div>
          <Link className="heading-btn" to="/contact">
            Contact Us
          </Link>
        </div>
        <div className="d-flex justify-content-center align-item-center">
          <img className="heading-image" src={headingImage} alt="heading" />
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
