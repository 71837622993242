import React from 'react';
import PropTypes from 'prop-types';
import {Link as GatsbyLink} from 'gatsby';
import clsx from 'clsx';
import * as classes from './Link.module.css';

function Link({to, children, color, size, fullWidth, invert, className}) {
  return (
    <GatsbyLink
      to={to}
      className={clsx(className, classes.link, {
        [classes.linkPrimary]: color === 'primary' && !invert,
        [classes.linkPrimaryInvert]: color === 'primary' && invert,
        [classes.linkRound]: color === 'round' && !invert,
        [classes.linkRoundInvert]: color === 'round' && invert,
        [classes.linkProcess]: color === 'process' && !invert,
        [classes.linkProcessInvert]: color === 'process' && invert,
        [classes.linkSecondary]: color === 'secondary' && !invert,
        [classes.linkSecondaryInvert]: color === 'secondary' && invert,
        [classes.linkContact]: color === 'contact' && !invert,
        [classes.linkContactInvert]: color === 'contact' && invert,
        [classes.linkGrowth]: color === 'growth' && !invert,
        [classes.linkGrowthInvert]: color === 'growth' && invert,
        [classes.linkEngage]: color === 'engage' && !invert,
        [classes.linkEngageInvert]: color === 'engage' && invert,
        [classes.linkBlue]: color === 'blue' && !invert,
        [classes.linkBlueInvert]: color === 'blue' && invert,
        [classes.linkSmall]: size === 'small',
        [classes.linkBig]: size === 'big',
        [classes.linkMedium]: size === 'medium',
        [classes.linkLong]: size === 'long',
        [classes.linkFullWidth]: fullWidth,
      })}
    >
      {children}
    </GatsbyLink>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  invert: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'round',
    'secondary',
    'process',
    'inherit',
    'contact',
    'engage',
    'growth',
    'blue',
  ]),
  size: PropTypes.oneOf(['small', 'normal', 'big', 'medium', 'long']),
  fullWidth: PropTypes.bool,
};

Link.defaultProps = {
  color: 'inherit',
  size: 'normal',
  fullWidth: false,
  invert: false,
};

export default Link;
