// extracted by mini-css-extract-plugin
export var banner = "CaseStudyCard-module--banner--8sQTq";
export var banner_container = "CaseStudyCard-module--banner_container--S6arS";
export var banner_img = "CaseStudyCard-module--banner_img--ObFsF";
export var card = "CaseStudyCard-module--card--o7+co";
export var description = "CaseStudyCard-module--description--xtd+n";
export var description_container = "CaseStudyCard-module--description_container--qU1n1";
export var heading = "CaseStudyCard-module--heading--UhTH-";
export var logo = "CaseStudyCard-module--logo--vGjB+";
export var logo_container = "CaseStudyCard-module--logo_container--PgDF1";
export var logo_small = "CaseStudyCard-module--logo_small--Ipo6A";
export var name = "CaseStudyCard-module--name--tIh3x";
export var position = "CaseStudyCard-module--position--oFcKR";
export var testimonial = "CaseStudyCard-module--testimonial--WiRwa";
export var title = "CaseStudyCard-module--title--frfVW";
export var values = "CaseStudyCard-module--values--7IGNz";