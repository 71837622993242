import React from 'react';
import {Helmet} from 'react-helmet';
import '../../../assets/css/connect.css';
import '../../../assets/css/live-ops.css';
import Footer from '../../Shared/Footer';
import HeadingSection from './Section/HeadingSection';
import ClientsLogoSection from './Section/ClientLogoSection';
import SolutionsSection from './Section/SolutionsSection';
import CaseStudySection from './Section/CaseStudySection';
import ExpertiseSection from './Section/ExpertiseSection';

function GamesLiveOpsTemplate() {
  return (
    <div className="games-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Games | Tintash</title>
      </Helmet>
      <HeadingSection />
      <ClientsLogoSection />
      <CaseStudySection />
      <SolutionsSection />
      <ExpertiseSection />
      <Footer showContactSection={false} showPortfolioSection={false} />
    </div>
  );
}

export default GamesLiveOpsTemplate;
