import React from 'react';
import powerIcon from '../../../../assets/images/connect/live-ops/power.svg';
import technicalIcon from '../../../../assets/images/connect/live-ops/technical.svg';
import roadmapIcon from '../../../../assets/images/connect/live-ops/roadmap.svg';
import teamIcon from '../../../../assets/images/connect/live-ops/team.svg';
import learningIcon from '../../../../assets/images/connect/live-ops/learning.svg';

import solutionBanner from '../../../../assets/images/connect/live-ops/solution-banner.png';

const SOLUTION_CARD_DATA = [
  {
    id: 1,
    icon: powerIcon,
    heading: 'What Powers Our Solution',
    description: 'In-depth data analysis and data driven decisions to boost targeted KPIs',
  },
  {
    id: 2,

    icon: technicalIcon,
    heading: 'Technical Debt Handling',
    description: 'Team of senior Unity devs with high adaptability, can take up any codebase',
  },
  {
    id: 3,

    icon: roadmapIcon,
    heading: 'Roadmap Execution',
    description: 'Design and develop entire roadmap iterative through data driven sprints',
  },
  {
    id: 4,

    icon: teamIcon,
    heading: 'Flexible Team Scaling',
    description: 'Ramp up the project team when needed to increase bandwidth',
  },
  {
    id: 5,

    icon: learningIcon,
    heading: 'Past Learnings',
    description: 'We leverage our years of learnings from successes and failures',
  },
];

function SolutionsSection() {
  return (
    <section className="solutions-section">
      <div className="details-wrapper col-lg-6">
        <h1 className="section-heading solution-heading mb-5">What Powers Our Solution</h1>
        {SOLUTION_CARD_DATA.map(card => (
          <div className="d-flex flex-column " key={card.id}>
            <div className="detail-card">
              <img className="detail-icon" src={card.icon} alt="power icon" />
              <div className="description-wrapper pl-3">
                <h2 className="description-heading">{card.heading}</h2>
                <p className="description-body">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="banner-wrapper col-lg-6">
        <h1 className="solution-heading-2">What Powers Our Solution</h1>
        <img className="solutions-banner" src={solutionBanner} alt="solution banner" />
      </div>
    </section>
  );
}

export default SolutionsSection;
