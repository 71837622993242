import React from 'react';

const CLIENTS_LOGO_DATA = [
  {
    src: require('../../../../assets/images/connect/live-ops/homa.svg').default,
    alt: 'Homa',
  },
  {
    src: require('../../../../assets/images/connect/live-ops/lion-studios.svg').default,
    alt: 'Lion Studios',
  },
  {
    src: require('../../../../assets/images/connect/live-ops/chillingo.svg').default,
    alt: 'chillingo',
  },

  {
    src: require('../../../../assets/images/connect/live-ops/pocket-gems.svg').default,
    alt: 'Pocket Gems',
  },
];

function ClientsLogoSection() {
  return (
    <section className="live-ops__main">
      <div className="clients-logo-container">
        {CLIENTS_LOGO_DATA.map(({src, alt}) => (
          <img key={src} className="clients-logo-img" src={src} alt={alt} />
        ))}
      </div>
    </section>
  );
}

export default ClientsLogoSection;
