/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react';
import CaseStudyCard from '../CaseStudyCard/CaseStudyCard';
import PrevIcon from '../../../../../assets/images/connect/live-ops/prev-icon.svg';
import NextIcon from '../../../../../assets/images/connect/live-ops/next-icon.svg';
import DetailIcon from '../../../../../assets/images/connect/live-ops/details-arrow.svg';
import Rating from '../../../../../assets/images/connect/live-ops/food-cutting-rating.svg';
import AccomplishmentOne from '../../../../../assets/images/connect/live-ops/food-cutting-accomplishment-1.svg';
import AccomplishmentTwo from '../../../../../assets/images/connect/live-ops/food-cutting-accomplishment-2.svg';

import AppStoreOne from '../../../../../assets/images/connect/live-ops/food-cutting-app-store-1.svg';
import AppStoreTwo from '../../../../../assets/images/connect/live-ops/food-cutting-app-store-2.svg';

import '../../../../../assets/css/carousal.css';

const CASE_STUDIES_DATA = [
  {
    id: 'Nail Salon',
    logoSrc: [
      require('../../../../../assets/images/connect/live-ops/nail-salon-logo.svg').default,
      require('../../../../../assets/images/connect/live-ops/nail-salon-logo-small.svg').default,
    ],
    screenSrc: require('../../../../../assets/images/connect/live-ops/nail-salon-screen.svg')
      .default,
    heading: 'Nail Salon 3D ( Launch & Live Ops )',
    description: (
      <div>
        Our first massively successful hyper-casual game. Designed and developed by Tintash - from
        ideation to launch - and published by Lion Studios.
      </div>
    ),
    details: (
      <div className="case-study-details-wrapper">
        <h2 className="case-study-details-heading">Live Ops Outcome ( Ongoing )</h2>
        <div className="case-study-other-container">
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">85% LTV+</span>
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+6% RRD1 </span>
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+3% RRD3 </span>
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+5 RVs </span>
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'ncase',
    logoSrc: [],
    screenSrc: require('../../../../../assets/images/connect/live-ops/ncase-screen.svg').default,
    heading: 'Case Study: Hyper-casual to hybrid-casual conversion of an Arcade Idle Shooter',
    description: (
      <div>
        LiveOps by Tintash, published by a leading hyper-casual publisher, originally built by
        another dev studio.
      </div>
    ),
    details: (
      <div className="case-study-details-wrapper">
        <h2 className="case-study-details-heading">Live Ops Outcome</h2>
        <div className="case-study-other-container">
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+25% </span> LTV
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+60%</span> Playtime
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+10%</span> Churn Reduction
            </p>
          </div>
          <div className="case-study-other-body latoLight">
            <img className="detail-icon pr-3" src={DetailIcon} alt="arrow" />
            <p className="m-0">
              <span className="text__yellow latoBold">+3% </span>RRD3
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'Food Cutting',
    logoSrc: [
      require('../../../../../assets/images/connect/live-ops/food-cutting-logo.svg').default,
      require('../../../../../assets/images/connect/live-ops/food-cutting-logo-small.svg').default,
    ],
    screenSrc: require('../../../../../assets/images/connect/live-ops/food-cutting-screen.svg')
      .default,
    heading: 'Food Cutting',
    description: (
      <div>
        Designed and developed by Tintash from ideation to launch. Published by Lion Studios.
      </div>
    ),
    details: (
      <div className="case-study-details-wrapper">
        <div className="case-study-rating-container text-left">
          <h3 className="case-study-rating-heading">Rating</h3>
          <img className="case-study-rating" src={Rating} alt="ratings" />
        </div>
        <div className="case-study-accomplishment-container text-left">
          <img className="case-study-accomplishment" src={AccomplishmentOne} alt="top games" />
          <img className="case-study-accomplishment" src={AccomplishmentTwo} alt="top games" />
        </div>
        <div className="case-study-app-store-container text-left">
          <img className="case-study-app-store" src={AppStoreOne} alt="app store" />
          <img className="case-study-app-store" src={AppStoreTwo} alt="app store" />
        </div>
        <p className="case-study-text text-left">20+ Million worldwide downloads</p>
      </div>
    ),
  },
  {
    id: 'Harvest Rush',
    logoSrc: [
      require('../../../../../assets/images/connect/live-ops/harvest-hush-logo.svg').default,
      require('../../../../../assets/images/connect/live-ops/harvest-hush-logo-small.svg').default,
    ],
    screenSrc: require('../../../../../assets/images/connect/live-ops/harvest-hush-screen.svg')
      .default,
    heading: 'Harvest Rush',
    description: (
      <div>
        Harvester Rush is an Arcade Idle game with extremely strong engagement KPIs.
        <br />
        <br /> The game features idle mechanics inspired by top casual idle games (clicker games
        e.g. Adventure Capitalist)
      </div>
    ),
    details: (
      <div className="case-study-details-wrapper">
        <h2 className="case-study-details-heading">Strong Engagement KPIs</h2>
        <div className="case-study-other-container">
          <div className="case-study-other-body">
            <p className="m-0">
              <span className="text__yellow latoBold">45%</span> RRD1
            </p>
          </div>
          <div className="case-study-other-body">
            <p className="m-0">
              <span className="text__yellow latoBold">45</span> Mins Playtime
            </p>
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   id: 'novi',
  //   logoSrc: [
  //     require('../../../../../assets/images/connect/live-ops/novi-logo.svg').default,
  //     require('../../../../../assets/images/connect/live-ops/novi-logo-small.svg').default,
  //   ],
  //   screenSrc: require('../../../../../assets/images/connect/live-ops/novi-screen.svg').default,
  //   heading: 'Novi',
  //   description: (
  //     <div>
  //       Novi is a casual puzzle game for mobile. It is based on a physical board game. We created
  //       this the game from scratch by providing expert game production, game design, art and Unity
  //       development services.
  //     </div>
  //   ),
  //   details: (
  //     <div className="case-study-details-wrapper">
  //       <h2 className="case-study-details-heading">Key Features</h2>
  //       <div className="case-study-body-container-2">
  //         <ul className="case-study-body-2">
  //           <li className="case-study-body-points">Puzzle gameplay </li>
  //           <li className="case-study-body-points">Level mastery (3 star scoring system)</li>
  //           <li className="case-study-body-points">Mission and quests system</li>
  //           <li className="case-study-body-points">Hints system</li>
  //           <li className="case-study-body-points">Daily puzzles</li>
  //         </ul>
  //         <ul className="case-study-body-2">
  //           <li className="case-study-body-points">Multi-tier achievements system</li>
  //           <li className="case-study-body-points">Leaderboard</li>
  //           <li className="case-study-body-points">Level designer tool</li>
  //           <li className="case-study-body-points">
  //             Level progression design (over 1400 levels split into 25+ level packs)
  //           </li>
  //           <li className="case-study-body-points">IAP & ad monetization</li>
  //         </ul>
  //       </div>
  //     </div>
  //   ),
  // },
];

function CaseStudyCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === CASE_STUDIES_DATA.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? CASE_STUDIES_DATA.length - 1 : prevIndex - 1));
  };

  const goToSlide = index => {
    setCurrentIndex(index);
  };

  return (
    <div className="case-study-carousel">
      <div className="carousel-wrapper " style={{transform: `translateX(-${currentIndex * 100}%)`}}>
        {CASE_STUDIES_DATA.map((caseStudy, index) => (
          <div
            key={caseStudy.id}
            className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
          >
            <CaseStudyCard {...caseStudy} />
          </div>
        ))}
      </div>
      <div className="carousel-prev" onClick={prevSlide}>
        <img className="carousel-prev-icon" src={PrevIcon} alt="prev icon" />
      </div>
      <div className="carousel-next" onClick={nextSlide}>
        <img className="carousel-next-icon" src={NextIcon} alt="next icon" />
      </div>
      <div className="carousel-indicators">
        {CASE_STUDIES_DATA.map((_, index) => (
          <div
            key={index}
            className={`carousel-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default CaseStudyCarousel;
