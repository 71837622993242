import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './CaseStudyCard.module.css';

function CaseStudyCard({id, logoSrc, screenSrc, heading, description, details}) {
  let logoClass = classes.logo;

  if (id === 'novi') {
    logoClass += ' w-50';
  } else if (id === 'ncase') {
    logoClass += ' w-25';
  }

  return (
    <article className={classes.card}>
      <div className={classes.banner_container}>
        {logoSrc.length > 0 && (
          <>
            <img className={logoClass} src={logoSrc[0]} alt="logo" />
            <img className={classes.logo_small} src={logoSrc[1]} alt="logo small" />
          </>
        )}
        <img className={classes.banner_img} src={screenSrc} alt="banner" />
      </div>
      <div className={classes.description_container}>
        <h2 className={classes.heading}>{heading}</h2>
        <p className={classes.description}>{description}</p>
        <div>{details}</div>
      </div>
    </article>
  );
}

CaseStudyCard.propTypes = {
  id: PropTypes.string.isRequired,
  logoSrc: PropTypes.arrayOf(PropTypes.string).isRequired,
  screenSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default CaseStudyCard;
